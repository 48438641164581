import React, { useEffect, useState } from 'react'
import { CanvasJSChart } from 'canvasjs-react-charts'
import { useTranslation } from 'react-i18next'
import { toaster, Message, Input, Notification, SelectPicker } from 'rsuite'
import LanguageButton from '../components/LanguageButton'
import { getCatStats, getStats } from '../http/API'
import tempStyle from '../components/inTime'
import sortByProperty from '../components/sortByProperty'
import HomeButton from '../components/HomeButton'
import { duration } from 'moment/moment'

function Stats () {
  const { t } = useTranslation()
  const [discSel, setDiscSel] = useState()
  const [selData, setSelData] = useState()
  const [filterOK, setFilterOK] = useState(true)
  const [customFilter, setCustomFilter] = useState('')
  const appendFilter = (t) => {
    setCustomFilter(cur => cur + ' ' + t)
  }
  const columns = {
    '"first_name"': t('common.name'),
    '"last_name"': t('common.surname'),
    '"gender"': t('stats.gender_ex'),
    '"birth_year"': t('stats.birth_year'),
    '"userid"': t('stats.patient_id'),
    'input_data.id': t('stats.entryid'),
    '"HB"': t('history.hb'),
    '"RBC"': t('history.rbc'),
    '"HCT"': t('history.hct'),
    '"Fer"': t('history.fer'),
    '"B12"': t('stats.b12'),
    '"result_anemia"': t('stats.res_anemia'),
    '"result_macro"': t('history.type.macro'),
    '"result_micro"': t('history.type.micro'),
    '"result_normo"': t('history.type.normo'),
    '"result_AHZ"': t('history.char.ahz'),
    '"result_ZhDA"': t('history.char.zhda'),
    '"result_B12"': t('history.char.b12')
  }
  const examples = [
    {
      label: t('stats.example.1.label'),
      sql: t('stats.example.1.sql')
    },
    {
      label: t('stats.example.2.label'),
      sql: t('stats.example.2.sql')
    },
    {
      label: t('stats.example.3.label'),
      sql: t('stats.example.3.sql')
    },
    {
      label: t('stats.example.4.label'),
      sql: t('stats.example.4.sql')
    },
    {
      label: t('stats.example.5.label'),
      sql: t('stats.example.5.sql')
    }
  ]

  const displaySQL1 = () => {
    toaster.push(<Notification type="info" header={t('stats.hint.1.title')} placement='topEnd' closable={true}>
      <p>{t('stats.hint.1.content')}</p>
      <a onClick={displaySQL2}>{t('stats.hint.2.title')}</a>
      <br />
      <a onClick={displaySQL3}>{t('stats.hint.3.title')}</a>
      <br />
      <a onClick={displaySQL4}>{t('stats.hint.4.title')}</a>
    </Notification>, { placement: 'topEnd', duration: 1000000 })
  }
  const displaySQL2 = () => {
    toaster.push(<Notification type="info" header={t('stats.hint.2.title')} placement='topEnd' closable={true}>
      <p>Для обращения к столбцу (<a onClick={() => appendFilter('"first_name"')}>имя пациента</a>, <a onClick={() => appendFilter('"birth_year"')}>год рождения</a> и т.п.) используйте <a onClick={displaySQL3}>его название в кавычках.</a> Для сравнения переменных можно использовать знаки <a onClick={() => appendFilter('<')}>&lt;</a>, <a onClick={() => appendFilter('>')}>&gt;</a>, <a onClick={() => appendFilter('=')}>=</a>. Для нескольких условий применяются операторы <a onClick={() => appendFilter('OR')}>OR (ИЛИ)</a> и <a onClick={() => appendFilter('AND')}>AND (И)</a>. Двоичные столбцы принимают значения <a onClick={() => appendFilter('true')}>true (истина)</a> и <a onClick={() => appendFilter('false')}>false (ложь)</a>.</p>
      <a onClick={displaySQL3}>{t('stats.hint.3.title')}</a>
      <br />
      <a onClick={displaySQL4}>{t('stats.hint.4.title')}</a>
    </Notification>, { placement: 'topEnd', duration: 1000000 })
  }
  const displaySQL3 = () => {
    toaster.push(<Notification type="info" header={t('stats.hint.3.title')} placement='topEnd' closable={true}>
      {Object.keys(columns).map((e, k) => <><a key={k} onClick={() => appendFilter(e)}>{e}: {columns[e]}</a><br /></>)}
    </Notification>, { placement: 'topEnd', duration: 1000000 })
  }
  const displaySQL4 = () => {
    toaster.push(<Notification type="info" header={t('stats.hint.4.title')} placement='topEnd' closable={true}>
      {examples.map((e, key) => <><p>{e.label}</p><a onClick={() => setCustomFilter(e.sql)}>{e.sql}</a><br /></>)}
    </Notification>, { placement: 'topEnd', duration: 1000000 })
  }

  const decodeVals = (val) => {
    const dic = {
      gender: {
        true: t('common.gender.male'),
        false: t('common.gender.female')
      },
      type: {
        micro: t('common.type.micro'),
        macro: t('common.type.macro'),
        normo: t('common.type.normo')
      },
      char: {
        AHZ: t('common.char.AHZ'),
        ZhDA: t('common.char.ZhDA'),
        B12: 'B12'
      }
    }
    const bakDic = {
      true: t('common.yes'),
      false: t('common.no')
    }
    return dic[discSel] ? dic[discSel][val] : bakDic[val] || val
  }

  const exify = (json) => {
    const r = Object.keys(json).map(key => ({
      label: decodeVals(key),
      y: json[key]
    }))
    r.sort(sortByProperty('label'))
    return r
  }

  const exifyMinor = (mas) => {
    const r = mas.map(e => ({ label: decodeVals(e[discSel]), y: e.count }))
    r.sort(sortByProperty('label'))
    return r
  }

  const [kkey, update] = useState(0)
  const discGraphData = [
    {
      label: t('stats.gender'),
      value: 'gender'
    },
    {
      label: t('stats.birth_year'),
      value: 'birth_year'
    },
    {
      label: t('stats.anemia'),
      value: 'result_anemia'
    },
    {
      label: t('stats.anemia_type'),
      value: 'type'
    },
    {
      label: t('stats.anemia_char'),
      value: 'char'
    }
  ]
  const titles = {
    gender: t('stats.chart.gender'),
    birth_year: t('stats.chart.birth_year'),
    result_anemia: t('stats.chart.result_anemia'),
    type: t('stats.chart.type'),
    char: t('stats.chart.char')
  }

  useEffect(() => {
    setFilterOK(false)
  }, [customFilter])
  useEffect(() => {
    setFilterOK(true)
  }, [kkey, discSel])
  useEffect(() => {
    const fetchData = async () => {
      ['birth_year', 'gender', 'result_anemia'].includes(discSel)
        ? await getStats(discSel, customFilter).then((res) => {
          const response = res.response
          if (response.success) {
            setSelData(exifyMinor(response.history))
          } else {
            toaster.push(<Message type="error" showIcon closable>{t(response.message)}</Message>)
          }
        })
        : ['type', 'char'].includes(discSel) && (await getCatStats(discSel, customFilter).then((res) => {
            const response = res.response
            if (response.success) {
              setSelData(exify(response.history[0]))
            } else {
              toaster.push(<Message type="error" showIcon closable>{t(response.message)}</Message>)
            }
          }))
    }
    fetchData()
  }, [discSel, kkey])

  const optionsDisc = {
    animationEnabled: true,
    exportEnabled: true,
    dataPointMaxWidth: 50,
    theme: 'light1',
    title: {
      text: titles[discSel]
    },
    axisY: {
      includeZero: true
    },
    data: [{
      showInLegend: true,
      legendText: '{label}',
      type: 'pie',
      indexLabelFontColor: '#5A5757',
      indexLabelPlacement: 'outside',
      indexLabel: '{y}',
      dataPoints: selData && discSel && selData
    }]
  }
  return (
    <div className='datamodal-wrapper' style={tempStyle}>
      <div id='datasave' className='calcmodal-body data' style={{ minWidth: '50%' }}>
        <h3><HomeButton />{t('stats.title')} <LanguageButton /></h3>
        <h5>{t('stats.subtitle')}</h5>
        <SelectPicker data={discGraphData} searchable={false} value={discSel} onChange={setDiscSel} placeholder={t('stats.filter.data')} />
        <h5>{t('stats.filter.title')}</h5>
        <Input value={customFilter} onChange={setCustomFilter} placeholder={t('stats.filter.input')} />
        <a onClick={displaySQL1}>{t('stats.filter.hint')}</a>
        {!filterOK && <button onClick={() => update(kkey + 1)}>{t('stats.filter.submit')}</button>}
        <CanvasJSChart options={optionsDisc} />
        {/* <CanvasJSChart options={optionsCont} /> */}
      </div>
    </div>
  )
}

export default Stats
