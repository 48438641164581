import * as FA from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Dropdown, Input, InputNumber, Loader, Message, Modal, Radio, RadioGroup, SelectPicker, Slider, Stack, Tabs, Text, toaster, Tooltip, Uploader, Whisper } from 'rsuite'
import HomeButton from '../components/HomeButton'
import LanguageButton from '../components/LanguageButton'
import tempStyle from '../components/inTime'
import { createDataset, createEns, delDS, delEns, downloadDataset, getDatasets, getPrevEns } from '../http/API'

function EnsLab () {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [prevEns, setPrevEns] = useState([])
  const [data, setData] = useState({})
  const [datasets, setDatasetsList] = useState([])
  const [file, setFile] = useState()
  const [working, setWorking] = useState(false)
  const [fileUp, setFileUp] = useState([])
  const [sureness, sure] = useState(false)
  const [log, setLog] = useState([])
  const [fileData, setFileData] = useState({
    name: '',
    note: ''
  })
  const [errs, setErrs] = useState({ arch: [], pars: [] })
  const [selDSEns, setSel] = useState({ ens: {}, ds: {} })
  const [curEns, setCurEns] = useState({
    name: t('ens.newens'),
    note: t('nn.note'),
    input_vars: [],
    output_vars: [],
    ens_type: null,
    conf: [],
    settings: 0
  })
  const input_vars = {
    gender: t('stats.gender_ex'),
    age: t('stats.birth_year'),
    HB: t('history.hb'),
    RBC: t('history.rbc'),
    HCT: t('history.hct'),
    Fer: t('history.fer'),
    B12: t('stats.b12'),
    RDW: '',
    PLT: '',
    TLC: '',
    MCH: '',
    MCHC: '',
    MCV: ''
  }
  const output_vars = {
    result_anemia: '',
    result_macro: '',
    result_micro: '',
    result_normo: '',
    result_AHZ: '',
    result_ZhDA: '',
    result_B12: ''
  }

  const selectify = (e) => e.map(i => ({ label: i, value: i }))

  // 1=RF 2=NB 3=DT 4=KNN 5=LR 6=RSVM 7=LSVM 8=XGB 9=Ada 10=LGBM
  const models = [
    {
      label: 'Random Forest',
      value: 1
    },
    {
      label: 'Naive Bayes',
      value: 2
    },
    {
      label: 'Decision Tree',
      value: 3
    },
    {
      label: 'k-Nearest Neighbors',
      value: 4
    },
    {
      label: 'Logistic Regression',
      value: 5
    },
    {
      label: 'Radial SVM',
      value: 6
    },
    {
      label: 'Linear SVM',
      value: 7
    }
  ]

  const modelsBoost = [
    {
      label: 'XGBoost',
      value: 8
    },
    {
      label: 'AdaBoost',
      value: 9
    },
    {
      label: 'LGBM',
      value: 10
    }
  ]

  const ensembles = [
    {
      label: 'Boosting',
      value: 1
    },
    {
      label: 'Bagging',
      value: 2
    },
    {
      label: 'Voting',
      value: 3
    },
    {
      label: 'Stacking',
      value: 4
    }
  ]

  const checkvars = (ds, type) => {
    if (selDSEns.ens.id) {
      const arr = ds.columns.map(e => e?.toLowerCase() || 'null')
      return selDSEns.ens[type + 'put_vars'].every(v => arr.includes(v.toLowerCase()))
    }
  }

  const tooltip = (errors, train) => {
    return (
      <Tooltip>
        {!train && errors.not_trained && <p>{t('nn.tooltip.nottrained')}</p>}
        {errors.missing_in && <p>{t('nn.tooltip.missingin')}</p>}
        {train && errors.missing_out && <p>{t('nn.tooltip.missingout')}</p>}
      </Tooltip>
    )
  }

  const downloadDS = async (id) => {
    setLoading(true)
    await downloadDataset(id).then(res => {
      setLoading(false)
      if (res.response.success) {
        const fileData = res.response.file
        const uint8Array = new Uint8Array(atob(fileData).split('').map(char => char.charCodeAt(0)))
        const blob = new Blob([uint8Array], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = res.response.name
        link.click()
      } else {
        toaster.push(<Message type="error" showIcon closable>{t(res.response.message)}</Message>)
      }
    })
  }

  const [updateKey, update] = useState(0)

  useEffect(function () {
    const fetchData = async () =>
      await getPrevEns().then(async (e) => {
        if (e.response.success) {
          setPrevEns(e.response.ens)
          await getDatasets().then(e => {
            if (e.response.success) {
              setDatasetsList(e.response.datasets)
            } else {
              toaster.push(<Message type="error" showIcon closable>{t(e.response.message)}</Message>)
            }
          })
        } else {
          toaster.push(<Message type="error" showIcon closable>{t(e.response.message)}</Message>)
        }
      })
    fetchData()
  }, [updateKey])

  useEffect(function () {
    setCurEns({
      ...curEns,
      conf: [],
      settings: 0
    })
  }, [curEns.ens_type])

  useEffect(function () {
    console.log(curEns)
    const arch = []
    const pars = []
    if (!curEns.ens_type) arch.push('Не выбран тип ансамбля!')
    if (curEns.ens_type === 1 && curEns?.conf?.length === 0) arch.push('Не выбрана модель!')
    if (curEns.ens_type === 2 && curEns?.conf?.length === 0) arch.push('Не выбраны модели!')
    if (curEns.ens_type === 2 && !curEns?.settings) arch.push('Не указано число моделей!')
    if (curEns.ens_type === 3 && curEns?.conf?.length === 0) arch.push('Не выбраны модели!')
    if (curEns.ens_type === 3 && !curEns?.settings) arch.push('Не выбран тип голосования!')
    if (curEns.ens_type === 4 && curEns?.conf?.length - 1 <= 0) arch.push('Не выбраны модели!')
    if (curEns.ens_type === 4 && curEns?.conf[0] === 0) arch.push('Не выбрана финальная модель!')

    if (curEns?.input_vars?.length === 0) pars.push(t('nn.alert.noin'))
    if (curEns?.output_vars?.length === 0) pars.push(t('nn.alert.noout'))

    setErrs({ arch, pars })
  }, [curEns])

  const fileSave = async (e) => {
    console.log(e)
    setFileUp(e.slice(-1))
    if (e && e.length > 0) {
      const f = e.slice(-1)[0].blobFile
      setFile(f)
    }
  }
  const uploadDataset = async () => {
    if (file && fileData.name !== '') {
      const form = new FormData()
      form.append('dataset', file, 'dataset' + file.type)
      form.append('name', fileData.name)
      form.append('note', fileData.note)
      setLoading(true)
      await createDataset(form).then(r => {
        setLoading(false)
        if (r.response.success) {
          toaster.push(<Message type="success" showIcon closable>{t('nn.alert.saved')}</Message>)
          setFileData({ name: '', note: '' })
          setFile(undefined)
          setFileUp([])
          update(updateKey + 1)
        } else {
          toaster.push(<Message type="error" showIcon closable>{t(r.response.message)}</Message>)
        }
      })
    } else {
      toaster.push(<Message type="error" showIcon closable>{t('nn.alert.missingfield')}</Message>)
    }
  }

  const saveEns = async () => {
    if (curEns.name) {
      setLoading(true)
      await createEns(curEns).then(res => {
        setLoading(false)
        if (res.response.success) {
          toaster.push(
            <Message type="success" showIcon closable>{t('nn.alert.savednn')}</Message>
          )
          update(updateKey + 1)
        } else {
          toaster.push(<Message type="error" showIcon closable>{t(res.response.message)}</Message>)
        }
      })
    } else {
      toaster.push(<Message type="warning" showIcon closable>{t('nn.alert.missingnnname')}</Message>)
    }
  }
  const deleteEns = async (id) => {
    if (!sureness) {
      sure(true)
      toaster.push(
        <Message type="warning" showIcon closable>{t('warning.clickagain')}</Message>
      )
      setTimeout(_ => {
        sure(false)
      }, [6000])
    } else {
      sure(false)
      await delEns(id).then(res => {
        if (res.response.success) {
          toaster.push(
            <Message type="success" showIcon closable>{t('nn.alert.deletednn')}</Message>
          )
          update(updateKey + 1)
        } else {
          toaster.push(<Message type="error" showIcon closable>{t(res.response.message)}</Message>)
        }
      })
    }
  }
  const deleteDS = async (id) => {
    if (!sureness) {
      sure(true)
      toaster.push(
        <Message type="warning" showIcon closable>{t('warning.clickagain')}</Message>
      )
      setTimeout(_ => {
        sure(false)
      }, [6000])
    } else {
      sure(false)
      await delDS(id).then(res => {
        if (res.response.success) {
          toaster.push(
            <Message type="success" showIcon closable>{t('nn.alert.deletedds')}</Message>
          )
          update(updateKey + 1)
        } else {
          toaster.push(<Message type="error" showIcon closable>{t(res.response.message)}</Message>)
        }
      })
    }
  }

  const changeNeurons = (layer, num, vars = undefined) => {
    const v = JSON.parse(JSON.stringify(curEns))
    if (num === 1) {
      if (layer === 0) {
        v.input_vars.push(vars)
      } else if (layer === -1) {
        v.output_vars.push(vars)
      }
    } else if (num === -1) {
      if (layer === 0) {
        v.input_vars.pop()
      } else if (layer === -1) {
        v.output_vars.pop()
      }
    }
    setCurEns(v)
  }

  const fitNumber = (e, max, min) => e > max ? max : e < min ? min : e

  const strip = (s) => s.length > 80 ? s.slice(0, 80) + '...' : s

  const appendLog = (s) => {
    setLog([...log, s]) // ????
    const node = document.createElement('li')
    const textnode = document.createTextNode(s)
    node.appendChild(textnode)
    document.getElementById('log-sheet').appendChild(node)
  }

  const startup = () => {
    setLog([])
    document.getElementById('log-sheet').innerHTML = ''
    if (selDSEns.type) {
      setWorking(true)
      const ws = new WebSocket('ws://' + process.env.REACT_APP_API_URL.split('//')[1] + 'ensemble' + '?token=Bearer ' + Cookies.get('token'))
      ws.onmessage = msg => {
        appendLog(msg.data)
      }
      ws.onclose = () => {
        appendLog(t('nn.alert.connectionclosed'))
        if (log.slice(-1)[0] === 'SUCCESS') {
          update(updateKey + 1)
          appendLog(t('nn.alert.execsuccess'))
        }
        setWorking(false)
      }
      ws.onopen = () => ws.send(JSON.stringify({
        token: 'Bearer ' + Cookies.get('token'),
        dataset: selDSEns.ds.id,
        ens: selDSEns.ens.id,
        type: selDSEns.type
      }))
    }
  }

  const popNeuron = (layer, name) => {
    const v = JSON.parse(JSON.stringify(curEns))
    if (layer === 0) {
      v.input_vars.splice(name, 1)
    } else if (layer === -1) {
      v.output_vars.splice(name, 1)
    }

    setCurEns(v)
  }
  const popModel = (name) => {
    const v = JSON.parse(JSON.stringify(curEns))
    v.conf.splice(name, 1)
    setCurEns(v)
  }
  const InputDropdown = ({ ...props }) => (
    <Dropdown {...props} noCaret trigger={['click', 'hover']} placement='bottomStart'>
      {Object.keys(input_vars).filter(v => !curEns?.input_vars?.includes(v)).map(v => <Dropdown.Item key={v} onSelect={() => setCurEns({ ...curEns, input_vars: [...curEns?.input_vars, v] })}>{v}</Dropdown.Item>)}
    </Dropdown>
  )

  const ModelDropdown = ({ ...props }) => (
    <Dropdown {...props} noCaret trigger={['click', 'hover']} placement='bottomStart'>
      {models.filter(v => !curEns?.conf?.includes(v.value)).map(v => <Dropdown.Item key={v.label} onSelect={() => setCurEns({ ...curEns, conf: [...curEns?.conf, v.value] })}>{v.label}</Dropdown.Item>)}
    </Dropdown>
  )

  const ModelDropdownStack = ({ ...props }) => (
    <Dropdown {...props} noCaret trigger={['click', 'hover']} placement='bottomStart'>
      {models.filter(v => !curEns?.conf.slice(1)?.includes(v.value)).map(v => <Dropdown.Item key={v.label} onSelect={() => setCurEns({ ...curEns, conf: curEns.conf.length === 0 ? [0, v.value] : [...curEns?.conf, v.value] })}>{v.label}</Dropdown.Item>)}
    </Dropdown>
  )

  const OutputDropdown = ({ ...props }) => (
    <Dropdown {...props} noCaret trigger={['click', 'hover']}>
      {Object.keys(output_vars).filter(v => !curEns.output_vars.includes(v)).map(v => <Dropdown.Item key={v} onSelect={() => changeNeurons(-1, 1, v)}>{v}</Dropdown.Item>)}
    </Dropdown>
  )

  const dsrender = datasets?.map(ds => {
    const errors = {
      not_trained: !selDSEns.ens.trained,
      missing_in: !checkvars(ds, 'in'),
      missing_out: !checkvars(ds, 'out')
    }
    return (<Stack className='nn-card' key={ds.id + '-ds'} direction='column' style={{ justifyContent: 'space-evenly', background: selDSEns.ds.id === ds.id ? '' : 'white' }}>
      <Stack direction='row' alignItems='stretch' ><FontAwesomeIcon icon={FA.faDatabase} size='xl' color={ds.predictor ? 'green' : 'blue'} style={{ marginRight: 10 }} /><p>{ds.name}</p></Stack>
      <Text>{ds.note}</Text>
      <Text>{ds?.columns?.length} {t('common.columns')} X {ds.rows} {t('common.rows')}</Text>
      <Whisper placement="top" trigger="hover" disabled={ds.columns.join(', ').length < 80} speaker={<Tooltip>{ds.columns.join(', ')}</Tooltip>}>
        <Text align='center'>{t('common.columns_n')}: {strip(ds.columns.join(', '))}</Text>
      </Whisper>
      {prevEns.length !== 0 && ds?.predictor && <Text> Прогноз от модели {prevEns.find(ens => ens.id === -ds.predictor).name}</Text>}
      {
        selDSEns.ens.id && !ds.predictor && <>
          <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={tooltip(errors, true)} disabled={!(errors.missing_out || errors.missing_in)}>
            <Button appearance='primary' color='blue' disabled={errors.missing_out || errors.missing_in} onClick={() => setSel({ ...selDSEns, ds, type: 'train' })}>{t('common.train')}</Button>
          </Whisper>
          <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={tooltip(errors, false)} disabled={!(errors.not_trained || errors.missing_in)}>
            <Button appearance='primary' color='blue' disabled={errors.not_trained || errors.missing_in} onClick={() => setSel({ ...selDSEns, ds, type: 'predict' })}>{t('common.forecast')}</Button>
          </Whisper>
        </>
      }
      <Button appearance='primary' color='green' onClick={() => downloadDS(ds.id)}>{t('common.download')}</Button>
      <Button appearance='primary' color='red' onClick={() => deleteDS(ds.id)}>{t('data.common.delete_button')}</Button>
    </Stack>)
  })
  return (
    <div className='datamodal-wrapper flex-column flex-stretch' style={tempStyle}>
      <div id='datasave' className='calcmodal-body data' style={{ background: 'white', width: '95%', height: '98%' }}>
        <h3><HomeButton />{t('ens.title')}<LanguageButton /></h3>
        <hr />
        <Tabs defaultActiveKey="1" className='ens-tabs'>
          <Tabs.Tab eventKey="1" title={t('ens.section.arch')}>
            <h3>{t('ens.props.pars')}</h3>
            <span>Тип ансамбля: </span><SelectPicker data={ensembles} searchable={false} value={curEns.ens_type} onChange={e => setCurEns({ ...curEns, ens_type: e })} /><br />
            <span>Искомая переменная: </span><SelectPicker data={selectify(Object.keys(output_vars))} searchable={false} value={curEns.output_vars[0] || null} onChange={e => setCurEns({ ...curEns, output_vars: [e] })} /><br />
            <span>Входные переменные: </span>{curEns.input_vars.length < Object.keys(input_vars).length && <InputDropdown appearance='primary' title="+" layer={1} />}{curEns.input_vars.map((v, i) => <span style={{ cursor: 'pointer' }} key={v} onClick={() => popNeuron(0, i)}> {v}; </span>)}
            <hr />
            <h3>{t('nn.props.arch')}</h3>
            {
              curEns.ens_type === 1
                ? <div>
                  <span>Выберите модель: </span> <SelectPicker data={modelsBoost} searchable={false} value={curEns.conf[0]} onChange={e => setCurEns({ ...curEns, conf: [e] })} />
                </div>
                : curEns.ens_type === 2
                  ? <div>
                    <span>Выберите модель: </span> <SelectPicker data={models} searchable={false} value={curEns.conf[0]} onChange={e => setCurEns({ ...curEns, conf: [e] })} /><br />
                    <span>Количество моделей: </span><Input style={{ width: '50px', display: 'inline' }} type='number' max={30} value={curEns.settings} onChange={e => setCurEns({ ...curEns, settings: fitNumber(e, 30, 1) })} />
                  </div>
                  : curEns.ens_type === 3
                    ? <div>
                      <span>Выберите модели: </span> {curEns.conf.length < Object.keys(models).length && <ModelDropdown appearance='primary' title="+" />}{curEns.conf.map((v, i) => <span style={{ cursor: 'pointer' }} key={v} onClick={() => popModel(i)}> {models.find(e => e.value === v).label}; </span>)}<br />
                      <span>Тип голосования: </span>
                      <RadioGroup value={curEns.settings} onChange={e => setCurEns({ ...curEns, settings: e })}>
                        <Radio value={1}>Soft</Radio>
                        <Radio value={2}>Hard</Radio>
                      </RadioGroup>
                    </div>
                    : curEns.ens_type === 4
                      ? <div>
                        <span>Выберите начальные модели: </span> {curEns.conf.length < Object.keys(models).length && <ModelDropdownStack appearance='primary' title="+" />}{curEns.conf.slice(1).map((v, i) => <span style={{ cursor: 'pointer' }} key={v} onClick={() => popModel(i + 1)}> {models.find(e => e.value === v).label}; </span>)}<br />
                        <span>Выберите финальную модель: </span> <SelectPicker data={models} searchable={false} value={curEns.conf[0]} onChange={e => setCurEns({ ...curEns, conf: [e, ...curEns.conf.slice(1)] })} /><br />
                      </div>
                      : <p>Выберите тип ансамбля!</p>
            }
            <hr />
            <h3>Состояние ансамбля:</h3>
            <ul>
              <li>Параметры{ }: {errs.pars.map((e, i) => <p key={i}>{e}</p>)}{errs.pars.length === 0 && 'OK'}</li>
              <li>Архитектура{ }:{errs.arch.map((e, i) => <p key={i}>{e}</p>)}{errs.arch.length === 0 && 'OK'}</li>
            </ul>
            <Input placeholder={t('common.name') + '*'} value={curEns.name} onChange={(e) => setCurEns({ ...curEns, name: e.slice(0, 40) })} />
            <Input placeholder={t('common.description')} value={curEns.note} onChange={(e) => setCurEns({ ...curEns, note: e.slice(0, 200) })} />
            <Button onClick={saveEns} appearance='primary' disabled={errs.pars.length + errs.arch.length > 0}>{t('nn.savenn')}</Button>
          </Tabs.Tab>

          <Tabs.Tab eventKey="2" title={t('nn.tab.saveds')}>
            <p>{t('nn.uploads')}</p>
            <Input placeholder={t('common.name') + '*'} value={fileData.name} onChange={(e) => setFileData({ ...fileData, name: e.slice(0, 40) })} />
            <Input placeholder={t('common.description')} value={fileData.note} onChange={(e) => setFileData({ ...fileData, note: e.slice(0, 200) })} />
            <Uploader
              accept='.xlsx'
              type='file'
              fileList={fileUp}
              onChange={fileSave}
              autoUpload={false}
              removable={false}
            ><Button appearance='primary'>{t('common.fileselect')}</Button></Uploader>
            <Button appearance='primary' onClick={uploadDataset}>{t('common.upload')}</Button>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>{dsrender}</div>
          </Tabs.Tab>
          <Tabs.Tab eventKey="3" title={t('nn.tab.trainpredict')}>
            <div style={{ width: '100%', height: '100%', justifyContent: 'space-between', display: 'flex' }}>
              <Stack style={{ width: '50%', maxHeight: '100%', overflowY: 'auto' }} wrap justifyContent='space-around'>
                {prevEns?.map(ens =>
                  <Stack className='nn-card' key={ens.id + '-ens'} direction='column' style={{ justifyContent: 'space-around', background: selDSEns.ens.id === ens.id ? '' : 'white' }}>
                    <Stack direction='row' alignItems='stretch' ><FontAwesomeIcon icon={FA.faCube} size='xl' color={ens.trained ? 'green' : 'orange'} style={{ marginRight: 10 }} /><p>{ens.name}</p></Stack>
                    <Text>{ens.note}</Text>
                    <Text>{t('common.train')}: {!ens.trained && t('common.not')} {t('nn.completed')}</Text>
                    <Text>{t('common.input')}: {ens.input_vars.join(', ')}</Text>
                    <Text>{t('common.output')}: {ens.output_vars.join(', ')}</Text>
                    {
                      ens.trained && <>
                        <Text>{t('common.stat.accuracy')}: {(ens?.accuracy * 100).toFixed(1)}%</Text>
                        {/* <Text>{t('common.stat.loss')} { } {t('nn.traintest')} {ens?.train_loss.toFixed(3)} - {ens?.val_loss.toFixed(3)}</Text> */}
                      </>
                    }
                    <Button appearance='primary' color='green' onClick={() => selDSEns.ens.id === ens.id ? setSel({ ...selDSEns, ens: {} }) : setSel({ ...selDSEns, ens })}>{t('common.select')}</Button>
                    <Button appearance='primary' onClick={() => { setCurEns(ens); toaster.push(<Message type="info" showIcon closable>{t('nn.alert.inserted')}</Message>) }}>{t('common.insert')}</Button>
                    <Button appearance='primary' color='red' onClick={() => deleteEns(ens.id)}>{t('data.common.delete_button')}</Button>
                  </Stack>
                )}
              </Stack>
              <Stack style={{ width: '50%', maxHeight: '100%', overflowY: 'auto' }} wrap justifyContent='space-around'>
                {dsrender}
              </Stack>
            </div>
          </Tabs.Tab>

        </Tabs>
      </div>
      {loading && <Loader backdrop content={t('common.loading')} vertical />}
      <Modal size='large' backdrop={false} open={selDSEns.ens.id && selDSEns.ds.id} onClose={() => setSel({ ds: {}, ens: {} })}>
        <Modal.Header><h3>{t('nn.console')}</h3></Modal.Header>
        <Modal.Body>
          {!working && <Button onClick={startup}>{t('common.start')}</Button>}
          {/* {log?.map(l => <p key = {l?.slice(0, 10)}>{l}</p>)} */}
          <ul id='log-sheet' />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default EnsLab
