import { $authHost, $host } from './index'
import { jwtDecode } from 'jwt-decode'
import Cookies from 'js-cookie'

export const addContactMessage = async (props) => {
  const { data } = await $host.post('auth/contact', { ...props })
  return { response: data }
}
export const registration = async (props) => {
  const { data } = await $host.post('auth/registration', { ...props })
  if (data.success) {
    await login(props.email, props.password)
    return { decyph: jwtDecode(data.token), response: data }
  } else {
    return { response: data }
  }
}

export const login = async (email, password) => {
  const { data } = await $host.post('auth/login', { email, password })
  if (data.success) {
    Cookies.set('first_name', data.user.first_name)
    Cookies.set('last_name', data.user.last_name)
    Cookies.set('email', data.user.email)
    Cookies.set('token', data.token)
    Cookies.set('gender', data.user.gender)

    return { decyph: jwtDecode(data.token), response: data }
  } else {
    return { response: data }
  }
}
export const generateRegKey = async (props) => {
  const { data } = await $authHost.post('admin/regkey', { props })
  return { response: data }
}
export const diagnose = async (props) => {
  const { data } = await $authHost.post('user/diagnose', { ...props })
  return { response: data }
}
export const deactivateKey = async (key) => {
  const { data } = await $authHost.post('admin/deactivate', { key })
  return { response: data }
}
export const searchPatients = async (name) => {
  const { data } = await $authHost.post('doctor/search', { name })
  return { response: data }
}
export const getKeys = async () => {
  const { data } = await $authHost.get('admin/getkeys')
  return { response: data }
}
export const getUsers = async () => {
  const { data } = await $authHost.get('admin/getusers')
  return { response: data }
}
export const getOwnHistory = async () => {
  const { data } = await $authHost.get('user/get_own_history')
  return { response: data }
}
export const getAllHistoryByPatient = async (id) => {
  const { data } = await $authHost.get(`doctor/get_history_patient?id=${id}`)
  return { response: data }
}
export const getAllHistory = async () => {
  const { data } = await $authHost.get('doctor/get_history_all')
  return { response: data }
}
export const getStats = async (cat, customFilter) => {
  const { data } = await $authHost.post('doctor/get_stats', { cat, customFilter })
  return { response: data }
}
export const getCatStats = async (cat, customFilter) => {
  const { data } = await $authHost.post('doctor/get_catstats', { cat, customFilter })
  return { response: data }
}
export const verifyToken = async () => {
  const { data } = await $authHost.get('auth/verify')
  return { response: data }
}
export const deleteDiagnosis = async (id) => {
  const { data } = await $authHost.delete(`user/delete_entry?id=${id}`)
  return { response: data }
}

export const regenKey = async () => {
  const { data } = await $authHost.get('user/regen_key')
  return { response: data }
}

export const getAllTrans = async () => {
  const { data } = await $authHost.get('doctor/get_trans')
  return { response: data }
}

export const saveTrans = async (list) => {
  const { data } = await $authHost.post('doctor/save_trans', { list })
  return { response: data }
}

export const delTrans = async (id) => {
  const { data } = await $authHost.delete('doctor/del_trans', { params: { id } })
  return { response: data }
}

export const downloadTrans = async (id) => {
  const { data } = await $authHost.get('doctor/download_trans', { params: { id } })
  return { response: data }
}

export const viewTrans = async (id) => {
  const { data } = await $authHost.get('doctor/view_trans', { params: { id } })
  return { response: data }
}

export const processTrans = async (id) => {
  const { data } = await $authHost.get('doctor/process_trans', { params: { id } })
  return { response: data }
}

export const evaluateTrans = async (id) => {
  const { data } = await $authHost.get('doctor/eval_trans', { params: { id } })
  return { response: data }
}

export const classifyTrans = async (id) => {
  const { data } = await $authHost.get('doctor/class_trans', { params: { id } })
  return { response: data }
}

export const getPrevNN = async () => {
  const { data } = await $authHost.get('doctor/neural')
  return { response: data }
}

export const createNN = async (props) => {
  const { data } = await $authHost.post('doctor/neural', { props })
  return { response: data }
}

export const delNN = async (id) => {
  const { data } = await $authHost.delete('doctor/neural', { params: { id } })
  return { response: data }
}

export const getPrevEns = async () => {
  const { data } = await $authHost.get('doctor/ensemble')
  return { response: data }
}

export const createEns = async (props) => {
  const { data } = await $authHost.post('doctor/ensemble', { props })
  return { response: data }
}

export const delEns = async (id) => {
  const { data } = await $authHost.delete('doctor/ensemble', { params: { id } })
  return { response: data }
}

export const getDatasets = async () => {
  const { data } = await $authHost.get('doctor/datasets')
  return { response: data }
}

export const delDS = async (id) => {
  const { data } = await $authHost.delete('doctor/datasets', { params: { id } })
  return { response: data }
}

export const createDataset = async (form) => {
  const { data } = await $authHost.post('doctor/datasets', form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return { response: data }
}

export const downloadDataset = async (id) => {
  const { data } = await $authHost.get('doctor/download_dataset', { params: { id } })
  return { response: data }
}
