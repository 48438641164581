import * as FA from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Input, Message, toaster } from 'rsuite'
import { deactivateKey, generateRegKey, getKeys, getUsers } from '../http/API'

const AdminSettings = () => {
  const { t } = useTranslation()
  const [data, setData] = useState({
    role: '',
    userid: jwtDecode(Cookies.get('token')).id,
    key: ''
  })
  const [updateKey, update] = useState(0)
  const [rkeys, setRKeys] = useState([])
  const [users, setUserlist] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const rkeyslist = await getKeys()
      if (rkeyslist.response.success) {
        setRKeys(rkeyslist.response.fields)
      }
      const usersList = await getUsers()
      if (usersList.response.success) {
        setUserlist(usersList.response.users)
      }
    }
    fetchData()
  }, [updateKey])
  const newKey = async () => {
    try {
      if (document.getElementById('key').value !== '' && document.getElementById('role').value !== '') {
        await generateRegKey(data).then((res) => {
          const response = res.response
          if (response.success) {
            toaster.push(<Message type="success" showIcon closable>{t(response.message)}</Message>)
            update(updateKey + 1)
          } else {
            toaster.push(<Message type="error" showIcon closable>{t(response.message)}</Message>)
          }
        })
      } else {
        toaster.push(<Message type="error" showIcon closable>{t('error.adminkey')}</Message>)
      }
    } catch (e) {
      toaster.push(
        <Message type="error" showIcon closable>{t('error.connectionerror')}</Message>
      )
      console.log(e)
    }
  }
  const deactivate = async (e) => {
    try {
      await deactivateKey(e).then((res) => {
        const response = res.response
        if (response.success) {
          toaster.push(<Message type="success" showIcon closable>{t(response.message)}</Message>)
          update(updateKey + 1)
        } else {
          toaster.push(<Message type="error" showIcon closable>{t(response.message)}</Message>)
        }
      })
    } catch (e) {
      toaster.push(
        <Message type="error" showIcon closable>{t('error.connectionerror')}</Message>
      )
      console.log(e)
    }
  }
  return (
    <div className='profile-wrapper'>
      <h3>{t('adminpanel.title')}</h3>
      <hr />
      <h2>{t('adminpanel.regkey.title')}</h2>
      <br />
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Input id='key' style={{ width: '30%', float: 'left' }} placeholder={t('adminpanel.regkey.placeholder')} onChange={e => setData({ ...data, key: e })} />
        <button className='button-subscribe' id='btn-confirm' style={{ width: '20%', float: 'center' }} onClick={newKey}>{t('adminpanel.regkey.button')}</button>
        <Input
          id='role' list='roles' style={{ width: '30%' }} placeholder={t('adminpanel.role.placeholder')} onChange={e => {
            setData({ ...data, role: e })
          }}
        />
        <datalist id='roles'>
          <option value='ADMIN'>{t('common.usertype.admin')}</option>
          <option value='USER'>{t('common.usertype.user')}</option>
          <option value='DOCTOR'>Врач</option>
        </datalist>
      </div>
      <br />
      <hr />
      <h2>{t('adminpanel.regkey.list')} </h2>
      <br />
      <div id='regkeys-list'>
        {rkeys.length === 0
          ? t('adminpanel.nokeys')
          : rkeys.map((f, key) => {
            return (
              <div
                key={key} style={{ cursor: 'default', background: f.used ? 'rgb(255, 163, 163)' : 'rgb(186, 255, 173)' }} onClick={() => {
                  navigator.clipboard.writeText(f.key)
                  toaster.push(
                    <Message type="success" showIcon closable>{`Ключ ${f.key} скопирован!`}</Message>
                  )
                }}
              >
                <h5>{t('common.key')} ({f.keytype})</h5>
                <h5>&quot;{f.key}&quot;</h5>
                <h5>({f.used ? 'Неактивен' : 'Активен'})</h5>
                <h5>{t('adminpanel.regkey.createdby')} {f.email} ({f.name}),</h5>
                <h5>{f.updatedAt.replace('T', ' ').slice(0, -8)}</h5>
                {!f.used &&
                  <Button style={{ width: 'fit-content', float: 'right', fontSize: '5px', padding: '1px' }} onClick={() => deactivate(f.key)}><FontAwesomeIcon icon={FA.faMinus} /></Button>}
              </div>
            )
          })}
      </div>
      <hr />
      <h2>{t('adminpanel.users.title')}</h2>
      {users?.length === 0
        ? t('adminpanel.users.notfound')
        : users?.map((f, key) => {
          return (
            <div id='user' key={key} style={{ width: '40%', margin: '5px' }}>
              <h4>Пользователь {f.first_name} {f.last_name} </h4>
              <br />
              <br />
              <div className='user-tile'>
                <div>
                  <h5>ID: {f.id}</h5>
                  <h5>Имя: {f.first_name}</h5>
                  <h5>Фамилия: {f.last_name}</h5>
                  <h5>{t('adminpanel.role.placeholder')}: {f.role}</h5>
                  <h5>{t('common.email')}: {f.email}</h5>
                  <h5>Пол: {f.gender ? 'Женский' : 'Мужской'}</h5>
                  <h5>Год рождения: {f.birth_year}</h5>
                  <h5>Зарегистрирован: {new Date(f.createdAt).toLocaleDateString('ru-RU')}</h5>
                  <br />
                </div>
              </div>
            </div>
          )
        })}
      <hr />
    </div>
  )
}

export default AdminSettings
