import * as FA from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Dropdown, Input, InputNumber, Loader, Message, Modal, SelectPicker, Slider, Stack, Tabs, Text, toaster, Tooltip, Uploader, Whisper } from 'rsuite'
import { io } from 'socket.io-client'
import HomeButton from '../components/HomeButton'
import LanguageButton from '../components/LanguageButton'
import tempStyle from '../components/inTime'
import { createDataset, createNN, delDS, delNN, downloadDataset, getDatasets, getPrevNN } from '../http/API'

function NNLab () {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [prevNN, setPrevNN] = useState([])
  const [data, setData] = useState({})
  const [datasets, setDatasetsList] = useState([])
  const [file, setFile] = useState()
  const [working, setWorking] = useState(false)
  const [fileUp, setFileUp] = useState([])
  const [sureness, sure] = useState(false)
  const [log, setLog] = useState([])
  const [fileData, setFileData] = useState({
    name: '',
    note: ''
  })
  const [selDSNN, setSel] = useState({ nn: {}, ds: {} })
  const [curNN, setCurNN] = useState({
    name: t('nn.newnn'),
    note: t('nn.note'),
    layer_conf: [3],
    input_vars: ['HB', 'RBC', 'HCT'],
    output_vars: ['result_anemia'],
    optimizer: 'adam',
    activation: ['relu'],
    epochs: 10,
    batch_size: 10,
    validation_split: 0.1,
    loss: 'binary_crossentropy'
  })
  const input_vars = {
    gender: t('stats.gender_ex'),
    birth_year: t('stats.birth_year'),
    HB: t('history.hb'),
    RBC: t('history.rbc'),
    HCT: t('history.hct'),
    Fer: t('history.fer'),
    B12: t('stats.b12'),
    RDW: '',
    PLT: '',
    TLC: '',
    MCH: '',
    MCHC: '',
    MCV: ''
  }
  const output_vars = {
    result_anemia: '',
    result_macro: '',
    result_micro: '',
    result_normo: '',
    result_AHZ: '',
    result_ZhDA: '',
    result_B12: ''
  }

  const selectify = (e) => e.map(i => ({ label: i, value: i }))
  const optimizers = selectify([
    'adam',
    'rmsprop',
    'sgd',
    'nadam'
  ])
  const losses = selectify([
    'binary_crossentropy',
    'categorical_crossentropy'
  ])

  const checkvars = (ds, type) => {
    if (selDSNN.nn.id) {
      const arr = ds.columns.map(e => e?.toLowerCase() || 'null')
      return selDSNN.nn[type + 'put_vars'].every(v => arr.includes(v.toLowerCase()))
    }
  }

  const tooltip = (errors, train) => {
    return (
      <Tooltip>
        {!train && errors.not_trained && <p>{t('nn.tooltip.nottrained')}</p>}
        {errors.missing_in && <p>{t('nn.tooltip.missingin')}</p>}
        {train && errors.missing_out && <p>{t('nn.tooltip.missingout')}</p>}
      </Tooltip>
    )
  }

  const downloadDS = async (id) => {
    setLoading(true)
    await downloadDataset(id).then(res => {
      setLoading(false)
      if (res.response.success) {
        const fileData = res.response.file
        const uint8Array = new Uint8Array(atob(fileData).split('').map(char => char.charCodeAt(0)))
        const blob = new Blob([uint8Array], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = res.response.name
        link.click()
      } else {
        toaster.push(<Message type="error" showIcon closable>{t(res.response.message)}</Message>)
      }
    })
  }

  const [updateKey, update] = useState(0)

  useEffect(function () {
    const fetchData = async () =>
      await getPrevNN().then(async (e) => {
        if (e.response.success) {
          setPrevNN(e.response.nn)
          await getDatasets().then(e => {
            if (e.response.success) {
              setDatasetsList(e.response.datasets)
            } else {
              toaster.push(<Message type="error" showIcon closable>{t(e.response.message)}</Message>)
            }
          })
        } else {
          toaster.push(<Message type="error" showIcon closable>{t(e.response.message)}</Message>)
        }
      })
    fetchData()
  }, [updateKey])

  const fileSave = async (e) => {
    console.log(e)
    setFileUp(e.slice(-1))
    if (e && e.length > 0) {
      const f = e.slice(-1)[0].blobFile
      setFile(f)
    }
  }
  const uploadDataset = async () => {
    if (file && fileData.name !== '') {
      const form = new FormData()
      form.append('dataset', file, 'dataset' + file.type)
      form.append('name', fileData.name)
      form.append('note', fileData.note)
      setLoading(true)
      await createDataset(form).then(r => {
        setLoading(false)
        if (r.response.success) {
          toaster.push(<Message type="success" showIcon closable>{t('nn.alert.saved')}</Message>)
          setFileData({ name: '', note: '' })
          setFile(undefined)
          setFileUp([])
          update(updateKey + 1)
        } else {
          toaster.push(<Message type="error" showIcon closable>{r.response.message}</Message>)
        }
      })
    } else {
      toaster.push(<Message type="error" showIcon closable>{t('nn.alert.missingfield')}</Message>)
    }
  }

  const saveNN = async () => {
    if (curNN.name) {
      setLoading(true)
      await createNN(curNN).then(res => {
        setLoading(false)
        if (res.response.success) {
          toaster.push(
            <Message type="success" showIcon closable>{t('nn.alert.savednn')}</Message>
          )
          update(updateKey + 1)
        } else {
          toaster.push(<Message type="error" showIcon closable>{t(res.response.message)}</Message>)
        }
      })
    } else {
      toaster.push(<Message type="warning" showIcon closable>{t('nn.alert.missingnnname')}</Message>)
    }
  }
  const deleteNN = async (id) => {
    if (!sureness) {
      sure(true)
      toaster.push(
        <Message type="warning" showIcon closable>{t('warning.clickagain')}</Message>
      )
      setTimeout(_ => {
        sure(false)
      }, [6000])
    } else {
      sure(false)
      await delNN(id).then(res => {
        if (res.response.success) {
          toaster.push(
            <Message type="success" showIcon closable>{t('nn.alert.deletednn')}</Message>
          )
          update(updateKey + 1)
        } else {
          toaster.push(<Message type="error" showIcon closable>{t(res.response.message)}</Message>)
        }
      })
    }
  }
  const deleteDS = async (id) => {
    if (!sureness) {
      sure(true)
      toaster.push(
        <Message type="warning" showIcon closable>{t('warning.clickagain')}</Message>
      )
      setTimeout(_ => {
        sure(false)
      }, [6000])
    } else {
      sure(false)
      await delDS(id).then(res => {
        if (res.response.success) {
          toaster.push(
            <Message type="success" showIcon closable>{t('nn.alert.deletedds')}</Message>
          )
          update(updateKey + 1)
        } else {
          toaster.push(<Message type="error" showIcon closable>{t(res.response.message)}</Message>)
        }
      })
    }
  }
  const InputDropdown = ({ ...props }) => (
    <Dropdown {...props} noCaret trigger={['click', 'hover']} placement='topStart'>
      {Object.keys(input_vars).filter(v => !curNN.input_vars.includes(v)).map(v => <Dropdown.Item key={v} onSelect={() => changeNeurons(0, 1, v)}>{v}</Dropdown.Item>)}
    </Dropdown>
  )

  const OutputDropdown = ({ ...props }) => (
    <Dropdown {...props} noCaret trigger={['click', 'hover']}>
      {Object.keys(output_vars).filter(v => !curNN.output_vars.includes(v)).map(v => <Dropdown.Item key={v} onSelect={() => changeNeurons(-1, 1, v)}>{v}</Dropdown.Item>)}
    </Dropdown>
  )

  const range = (n) => [...Array(n).keys()]

  const changeNeuronsHidden = (layer, num) => {
    const v = JSON.parse(JSON.stringify(curNN))
    const count = v.layer_conf[layer]
    if (count + num < 0 || count + num > 15) {
      v.layer_conf[layer] = count
    } else if (count + num === 0) {
      v.layer_conf.splice(layer, 1)
    } else {
      v.layer_conf[layer] = count + num
    }
    setCurNN(v)
  }

  const changeNeurons = (layer, num, vars = undefined) => {
    const v = JSON.parse(JSON.stringify(curNN))
    if (num === 1) {
      if (layer === 0) {
        v.input_vars.push(vars)
      } else if (layer === -1) {
        v.output_vars.push(vars)
      }
    } else if (num === -1) {
      if (layer === 0) {
        v.input_vars.pop()
      } else if (layer === -1) {
        v.output_vars.pop()
      }
    }
    setCurNN(v)
  }

  const popNeuron = (layer, name) => {
    const v = JSON.parse(JSON.stringify(curNN))
    if (layer === 0) {
      v.input_vars.splice(name, 1)
    } else if (layer === -1) {
      v.output_vars.splice(name, 1)
    }

    setCurNN(v)
  }

  const changeLayers = (num) => {
    const v = JSON.parse(JSON.stringify(curNN))
    const count = v.layer_conf.length
    if (count + num < 0 || count + num > 10) {
      console.log('Max/min layers reached')
    } else {
      if (num === 1) { v.layer_conf.push(1) } else v.layer_conf.pop()
      setCurNN(v)
    }
  }

  const fitNumber = (e, max, min) => e > max ? max : e < min ? min : e

  const strip = (s) => s.length > 80 ? s.slice(0, 80) + '...' : s

  const appendLog = (s) => {
    setLog([...log, s]) // ????
    const node = document.createElement('li')
    const textnode = document.createTextNode(s)
    node.appendChild(textnode)
    document.getElementById('log-sheet').appendChild(node)
  }

  const startup = () => {
    setLog([])
    document.getElementById('log-sheet').innerHTML = ''
    if (selDSNN.type) {
      setWorking(true)
      const ws = new WebSocket('ws://' + process.env.REACT_APP_API_URL.split('//')[1] + 'neural' + '?token=Bearer ' + Cookies.get('token'))
      ws.onmessage = msg => {
        appendLog(msg.data)
      }
      ws.onclose = () => {
        appendLog(t('nn.alert.connectionclosed'))
        if (log.slice(-1)[0] === 'SUCCESS') {
          update(updateKey + 1)
          appendLog(t('nn.alert.execsuccess'))
        }
        setWorking(false)
      }
      ws.onopen = () => ws.send(JSON.stringify({
        token: 'Bearer ' + Cookies.get('token'),
        dataset: selDSNN.ds.id,
        nn: selDSNN.nn.id,
        type: selDSNN.type
      }))
    }
  }

  return (
    <div className='datamodal-wrapper flex-column flex-stretch' style={tempStyle}>
      <div id='datasave' className='calcmodal-body data' style={{ background: 'white', width: '95%', height: '98%' }}>
        <h3><HomeButton />{t('nn.title')}<LanguageButton /></h3>
        <hr />
        <Tabs defaultActiveKey="1" className='nn-tabs'>
          <Tabs.Tab eventKey="1" title={t('nn.props.arch')}>
            <div className='nn-base'>
              <Button className='nn-layer-put' onClick={() => changeLayers(-1)} appearance='primary'>- {t('nn.props.layer')}</Button>

              <div className='nn-layer'>
                {range(curNN.input_vars.length).map(i => <><div key={i} className='nn-neuron' onClick={() => popNeuron(0, i)} />{curNN.input_vars[i]}</>)}
                <div className='nn-buttons'>
                  <InputDropdown appearance='primary' title="+" layer={1} />
                  <Button appearance='primary' onClick={() => changeNeurons(0, -1)}>-</Button>
                </div>
                <p>({curNN.input_vars.length})</p>
                <p>{t('nn.props.inputlayer')}</p>
                <p>Input</p>
              </div>

              {range(curNN.layer_conf.length).map(e => <div key={e} className='nn-layer'>
                {range(curNN.layer_conf[e]).map(i => <div key={i} onClick={() => changeNeuronsHidden(e, -1)} className='nn-neuron' />)}
                <div className='nn-buttons'>
                  <Button appearance='primary' onClick={() => changeNeuronsHidden(e, 1)}>+</Button>
                  <Button appearance='primary' onClick={() => changeNeuronsHidden(e, -1)}>-</Button>
                </div>
                <p>({curNN.layer_conf[e]})</p>
                <p>{t('nn.props.layer')} {e + 1}</p>
                <p>Dense</p>
              </div>)}

              <div className='nn-layer'>
                {range(curNN.output_vars.length).map(i => <><div key={i} className='nn-neuron' onClick={() => popNeuron(-1, i)} />{curNN.output_vars[i]}</>)}
                <div className='nn-buttons'>
                  <OutputDropdown appearance='primary' title="+" layer={1} />
                  <Button appearance='primary' onClick={() => changeNeurons(-1, -1)}>-</Button>
                </div>
                <p>({curNN.output_vars.length})</p>
                <p>{t('nn.props.outputlayer')}</p>
                <p>Dense</p>
              </div>

              <Button className='nn-layer-put' onClick={() => changeLayers(1)} appearance='primary'>+ {t('nn.props.layer')}</Button>
            </div>

          </Tabs.Tab>
          <Tabs.Tab eventKey="2" title={t('nn.props.hyper')}>
            <p>{t('nn.props.optimizer')} <SelectPicker value={curNN.optimizer} onChange={(e) => setCurNN({ ...curNN, optimizer: e })} searchable={false} data={optimizers} /></p>
            <p>{t('nn.props.loss')} <SelectPicker value={curNN.loss} onChange={(e) => setCurNN({ ...curNN, loss: e })} searchable={false} data={losses} /></p>
            <p>{t('nn.props.epochs')}<InputNumber max={100} min={1} value={curNN.epochs} onChange={(e) => setCurNN({ ...curNN, epochs: fitNumber(e, 100, 1) })} /></p>
            <p>{t('nn.props.val')}
              <Slider
                min={5}
                step={5}
                max={50}
                graduated
                progress
                renderMark={mark => {
                  return mark + ' %'
                }}
                value={curNN.validation_split * 100} onChange={(e) => setCurNN({ ...curNN, validation_split: e / 100 })}
              /></p>
            <p>{t('nn.props.batchsize')} <InputNumber value={curNN.batch_size} onChange={(e) => setCurNN({ ...curNN, batch_size: fitNumber(e, 32, 1) })} /></p>
          </Tabs.Tab>
          <Tabs.Tab eventKey="3" title={t('nn.tab.saveds')}>
            <p>{t('nn.state')}:</p>
            <ul>
              <li>{t('nn.props.arch')}{}: {curNN.input_vars.length === 0 && <p>{t('nn.alert.noin')}</p>}{curNN.output_vars.length === 0 && <p>{t('nn.alert.noout')}</p>}{curNN.input_vars.length > 0 && curNN.output_vars.length > 0 && 'OK'}</li>
              <li>{t('nn.props.hyper')}: OK</li>
            </ul>
            <Input placeholder={t('common.name') + '*'} value={curNN.name} onChange={(e) => setCurNN({ ...curNN, name: e.slice(0, 40) })} />
            <Input placeholder={t('common.description')} value={curNN.note} onChange={(e) => setCurNN({ ...curNN, note: e.slice(0, 200) })} />
            <Button onClick={saveNN} appearance='primary' disabled={curNN.input_vars.length === 0 || curNN.output_vars.length === 0}>{t('nn.savenn')}</Button>
            <hr />
            <p>{t('nn.uploads')}</p>
            <Input placeholder={t('common.name') + '*'} value={fileData.name} onChange={(e) => setFileData({ ...fileData, name: e.slice(0, 40) })} />
            <Input placeholder={t('common.description')} value={fileData.note} onChange={(e) => setFileData({ ...fileData, note: e.slice(0, 200) })} />
            <Uploader
              accept='.xlsx'
              type='file'
              fileList={fileUp}
              onChange={fileSave}
              autoUpload={false}
              removable={false}
            ><Button appearance='primary'>{t('common.fileselect')}</Button></Uploader>
            <Button appearance='primary' onClick={uploadDataset}>{t('common.upload')}</Button>
          </Tabs.Tab>
          <Tabs.Tab eventKey="4" title={t('nn.tab.trainpredict')}>
            <div style={{ width: '100%', height: '100%', justifyContent: 'space-between', display: 'flex' }}>
              <Stack style={{ width: '50%', maxHeight: '100%', overflowY: 'auto' }} wrap justifyContent='space-around'>
                {prevNN?.map(nn =>
                  <Stack className='nn-card' key={nn.id + '-nn'} direction='column' style={{ justifyContent: 'space-around', background: selDSNN.nn.id === nn.id ? '' : 'white' }}>
                    <Stack direction='row' alignItems='stretch' ><FontAwesomeIcon icon={FA.faBrain} size='xl' color={nn.trained ? 'green' : 'orange'} style={{ marginRight: 10 }} /><p>{nn.name}</p></Stack>
                    <Text>{nn.note}</Text>
                    <Text>{t('common.train')}: {!nn.trained && t('common.not')} {t('nn.completed')}</Text>
                    <Text>{t('common.input')}: {nn.input_vars.join(', ')}</Text>
                    <Text>{t('common.output')}: {nn.output_vars.join(', ')}</Text>
                    {
                      nn.trained && <>
                    <Text>{t('common.stat.accuracy')} {} {t('nn.traintest')} {(nn?.train_accuracy * 100).toFixed(1)}%  - {(nn?.val_accuracy * 100).toFixed(1)}%</Text>
                    <Text>{t('common.stat.loss')} {} {t('nn.traintest')} {nn?.train_loss.toFixed(3)} - {nn?.val_loss.toFixed(3)}</Text>
                      </>
                    }
                    <Button appearance='primary' color='green' onClick={() => selDSNN.nn.id === nn.id ? setSel({ ...selDSNN, nn: {} }) : setSel({ ...selDSNN, nn })}>{t('common.select')}</Button>
                    <Button appearance='primary' onClick={() => { setCurNN(nn); toaster.push(<Message type="info" showIcon closable>{t('nn.alert.inserted')}</Message>) }}>{t('common.insert')}</Button>
                    <Button appearance='primary' color='red' onClick={() => deleteNN(nn.id)}>{t('data.common.delete_button')}</Button>
                  </Stack>
                )}
              </Stack>
              <Stack style={{ width: '50%', maxHeight: '100%', overflowY: 'auto' }} wrap justifyContent='space-around'>
                {datasets?.map(ds => {
                  const errors = {
                    not_trained: !selDSNN.nn.trained,
                    missing_in: !checkvars(ds, 'in'),
                    missing_out: !checkvars(ds, 'out')
                  }
                  return (<Stack className='nn-card' key={ds.id + '-ds'} direction='column' style={{ justifyContent: 'space-evenly', background: selDSNN.ds.id === ds.id ? '' : 'white' }}>
                    <Stack direction='row' alignItems='stretch' ><FontAwesomeIcon icon={FA.faDatabase} size='xl' color='blue' style={{ marginRight: 10 }} /><p>{ds.name}</p></Stack>
                    <Text>{ds.note}</Text>
                    <Text>{ds.columns.length} {t('common.columns')} X {ds.rows} {t('common.rows')}</Text>
                    <Whisper placement="top" trigger="hover" disabled={ds.columns.join(', ').length < 80} speaker={<Tooltip>{ds.columns.join(', ')}</Tooltip>}>
                      <Text align='center'>{t('common.columns_n')}: {strip(ds.columns.join(', '))}</Text>
                    </Whisper>
                    {
                      selDSNN.nn.id && <>
                        <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={tooltip(errors, true)} disabled={!(errors.missing_out || errors.missing_in)}>
                          <Button appearance='primary' color='blue' disabled={errors.missing_out || errors.missing_in} onClick={() => setSel({ ...selDSNN, ds, type: 'train' })}>{t('common.train')}</Button>
                        </Whisper>
                        <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={tooltip(errors, false)} disabled={!(errors.not_trained || errors.missing_in)}>
                          <Button appearance='primary' color='blue' disabled={errors.not_trained || errors.missing_in} onClick={() => setSel({ ...selDSNN, ds, type: 'predict' })}>{t('common.forecast')}</Button>
                        </Whisper>
                      </>
                    }
                    <Button appearance='primary' color='green' onClick={() => downloadDS(ds.id)}>{t('common.download')}</Button>
                    <Button appearance='primary' color='red' onClick={() => deleteDS(ds.id)}>{t('data.common.delete_button')}</Button>
                  </Stack>)
                })}
              </Stack>
            </div>
          </Tabs.Tab>

        </Tabs>
      </div>
      {loading && <Loader backdrop content={t('common.loading')} vertical />}
      <Modal size='large' backdrop={false} open={selDSNN.nn.id && selDSNN.ds.id} onClose={() => setSel({ ds: {}, nn: {} })}>
        <Modal.Header><h3>{t('nn.console')}</h3></Modal.Header>
        <Modal.Body>
          {!working && <Button onClick={startup}>{t('common.start')}</Button>}
          {/* {log?.map(l => <p key = {l?.slice(0, 10)}>{l}</p>)} */}
          <ul id = 'log-sheet' />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default NNLab
