import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toaster, Message, Button, Loader, Table } from 'rsuite'
import HomeButton from '../components/HomeButton'
import LanguageButton from '../components/LanguageButton'
import PreviewPane from '../components/PreviewPane'
import tempStyle from '../components/inTime'
import StatusLink from '../components/statusLink'
import { classifyTrans, getAllTrans, viewTrans } from '../http/API'

function DataClassification () {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [transactions, setTrans] = useState()
  const [previewTrans, setPreviewTrans] = useState()

  const [updateKey, update] = useState(0)

  useEffect(() => {
    const fetchData = async () => {
      await getAllTrans().then(e => {
        if (e.response.success) {
          setTrans(e.response.trans)
        } else {
          toaster.push(<Message type="error" showIcon closable>{t(e.response.message)}</Message>)
        }
      })
    }
    fetchData()
  }, [updateKey])

  const viewTransaction = async (id) => {
    setLoading(true)
    await viewTrans(id).then(res => {
      setLoading(false)
      if (res.response.success) {
        setPreviewTrans(res.response.data)
      } else {
        toaster.push(<Message type="error" showIcon closable>{t(res.response.message)}</Message>)
      }
    })
  }
  const classifyTransaction = async (id) => {
    setLoading(true)
    await classifyTrans(id).then(res => {
      setLoading(false)
      if (res.response.success) {
        toaster.push(
          <Message type="success" showIcon closable>{t('data.class.success')}</Message>
        )
        update(updateKey + 1)
      } else {
        toaster.push(<Message type="error" showIcon closable>{t(res.response.message)}</Message>)
      }
    })
  }

  return (
    <div className='datamodal-wrapper flex-column flex-stretch' style={tempStyle}>
      <div id='datasave' className='calcmodal-body data' style={{ background: 'white' }}>
        <h3><HomeButton />{t('profile.data_class')}<LanguageButton /></h3>
        <hr />
        {transactions &&
          <Table
            height={500}
            // autoHeight
            width={1200}
            hover
            rowHeight={70}
            data={transactions.filter(rec => rec.status === 2 || rec.status === 3)}
            className='trans-table'
            renderEmpty={() => <p style={{ margin: 'auto', width: 'fit-content' }}>{t('data.class.nodata')}</p>}
          >
            <Table.Column flexGrow={0.5} align="center" fixed>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.Cell dataKey="id" />
            </Table.Column>
            <Table.Column flexGrow={1.5} align="center" fixed>
              <Table.HeaderCell>{t('data.common.created_at')}</Table.HeaderCell>
              <Table.Cell>{rowData => new Date(rowData.createdAt).toLocaleString('ru')}</Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1.5} align="center" fixed>
              <Table.HeaderCell>{t('data.common.updated_at')}</Table.HeaderCell>
              <Table.Cell>{rowData => new Date(rowData.updatedAt).toLocaleString('ru')}</Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1.5} align="center" fixed>
              <Table.HeaderCell>{t('data.common.last_stage')}</Table.HeaderCell>
              <Table.Cell>{rowData => <StatusLink stage={rowData.status}/>}</Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1.5} align="center" fixed>
              <Table.HeaderCell>{t('data.common.next_stage')}</Table.HeaderCell>
              <Table.Cell>{rowData => <StatusLink stage={rowData.status + 1}/>}</Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1.5} align="center" fixed>
              <Table.HeaderCell>{t('data.common.preview')}</Table.HeaderCell>
              <Table.Cell>{rowData => <Button appearance='primary' onClick={() => viewTransaction(rowData.id)} color='cyan'>{t('data.common.preview_button')}</Button>}</Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={2} align="center" fixed>
              <Table.HeaderCell>{t('data.class.title')}</Table.HeaderCell>
              <Table.Cell>{rowData => <Button
                appearance='primary'
                onClick={() => {
                  rowData.status === 2
                    ? classifyTransaction(rowData.id)
                    : toaster.push(
                  <Message type="info" showIcon closable>Классификация уже была произведена ранее!</Message>
                    )
                }} color='green'>{t('data.class.button')}</Button>}</Table.Cell>
            </Table.Column>
          </Table>}
      </div>

      <PreviewPane previewTrans={previewTrans} setPreviewTrans={setPreviewTrans} />
      {loading && <Loader backdrop content={t('common.loading')} vertical />}
    </div>
  )
}

export default DataClassification
