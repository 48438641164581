import { createContext, Suspense } from 'react'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'rsuite/dist/rsuite.min.css'
import App from './App'
import './styles/auth.css'
import './styles/index.css'
// import './styles/aboutus.css'

import { createRoot } from 'react-dom/client'
import './i18n'
import './styles/calcmodal.css'
import './styles/title.css'

export const Context = createContext(null)

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Suspense fallback={(<div>Loading</div>)}>
      <App />
  </Suspense>)
