import Cookies from 'js-cookie'
import { jwtDecode } from 'jwt-decode'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AdminSettings from './pages/AdminSettings'
import Profile from './pages/Profile'

import { verifyToken } from './http/API'
import Auth from './pages/Auth'
import DataAcquisition from './pages/DataAcquisition'
import DataClassification from './pages/DataClassification'
import DataEvaluation from './pages/DataEvaluation'
import DataProcessing from './pages/DataProcessing'
import Diagnose from './pages/Diagnose'
import History from './pages/History'
import NNLab from './pages/NNLab'
import PatientPassport from './pages/PatientPassport'
import Stats from './pages/Stats'
import Ensembling from './pages/Ensembling'

function App () {
  const isVerifiedToken = async () => {
    await verifyToken().then(res => {
      if (res.response.success) {
        Cookies.set('first_name', res.response.first_name)
        Cookies.set('last_name', res.response.last_name)
        Cookies.set('email', res.response.email)
      } else {
        Cookies.remove('token')
        window.location.reload()
      }
    })
  }
  const token = Cookies.get('token')
  let userRole
  try {
    userRole = token ? jwtDecode(token).role : undefined
  } catch {
    Cookies.remove('token')
    window.location.reload()
  }
  if (token) {
    try {
      const isTokenExpired = t => Date.now() >= (JSON.parse(atob(t.split('.')[1]))).exp * 1000
      if (isTokenExpired(token)) {
        Cookies.remove('token')
        window.location.reload()
      }
      isVerifiedToken()
      console.log('token check')
    } catch {
      Cookies.remove('token')
      window.location.reload()
    }
  }
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/auth' element={<Auth />} />
          <Route path='/profile' element={userRole ? <Profile /> : <Auth />} />
          <Route path='/diagnosis' element={userRole ? <Diagnose /> : <Auth />} />
          <Route path='/history' element={userRole ? <History /> : <Auth />} />
          <Route path='/data_acq' element={userRole ? <DataAcquisition /> : <Auth />} />
          <Route path='/data_proc' element={userRole ? <DataProcessing /> : <Auth />} />
          <Route path='/data_eval' element={userRole ? <DataEvaluation /> : <Auth />} />
          <Route path='/data_class' element={userRole ? <DataClassification /> : <Auth />} />
          <Route path='/ensembling' element={userRole ? <Ensembling /> : <Auth />} />
          <Route path='/neural' element={userRole ? <NNLab /> : <Auth />} />
          <Route path='/patients' element={['ADMIN', 'DOCTOR'].includes(userRole) ? <PatientPassport /> : <Auth />} />
          <Route path='/stats' element={['ADMIN', 'DOCTOR'].includes(userRole) ? <Stats /> : <Auth />} />
          <Route path='/admin' element={userRole === 'ADMIN' ? <AdminSettings /> : Profile} />
          <Route exact path='*' element={<Auth />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
