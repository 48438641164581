/* eslint-disable prefer-regex-literals */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Input,
  InputGroup,
  Message,
  Radio,
  RadioGroup,
  toaster,
  Tooltip,
  Whisper
} from 'rsuite'
import LanguageButton from '../components/LanguageButton'
import { login, registration } from '../http/API'

import Eye from '@rsuite/icons/legacy/Eye'
import EyeSlash from '@rsuite/icons/legacy/EyeSlash'

const Auth = (props) => {
  const { t } = useTranslation()
  const [authType, setType] = useState('login')
  const [datareg, setDataReg] = useState({})
  const [datalogin, setDatalogin] = useState({
    email: '',
    password: ''
  })
  const [eye, setEye] = useState('eye')
  const [passwordType, setPasswordType] = useState('password')
  const [loading, setLoading] = useState(false)
  const togglePassword = () => {
    if (eye === 'eye') {
      setEye('eye-slash')
      setPasswordType('text')
    } else {
      setEye('eye')
      setPasswordType('password')
    }
  }
  const onSubmitLogin = async () => {
    // const tempErrors = validate(datalogin)
    // setErrors(temp_errors);
    setLoading(true)
    try {
      await login(datalogin.email, datalogin.password).then((res) => {
        setLoading(false)
        const response = res.response
        if (response.success) {
          toaster.push(<Message type="success" showIcon closable>{t(response.message)}</Message>)
          setTimeout(2000)
          window.location.href = '/profile'
          // history.push('/profile')
        } else {
          toaster.push(<Message type="error" showIcon closable>{t(response.message)}</Message>)
        }
      })
    } catch (e) {
      setLoading(false)
      toaster.push(
        <Message type="error" showIcon closable>{t('error.noconnection')}</Message>
      )
      console.log(e)
    }
  }
  const onSubmitReg = async () => {
    const tempErrors = validate(datareg)
    // setErrors(temp_errors);
    if (Object.keys(tempErrors).length === 0) {
      try {
        await registration(datareg).then((res) => {
          setLoading(false)
          const response = res.response
          if (response.success) {
            toaster.push(<Message type="success" showIcon closable>{t(response.message)}</Message>)
            window.location.href = '/profile'
            // history.push('/profile')
          } else {
            toaster.push(<Message type="error" showIcon closable>{t(response.message)}</Message>)
          }
        })
      } catch (e) {
        setLoading(false)
        toaster.push(
          <Message type="error" showIcon closable>{t('error.connectionerror')}</Message>
        )
        console.log(e)
      }
    } else {
      console.log(Object.values(tempErrors))
      Object.values(tempErrors).forEach(e => {
        toaster.push(<Message type="error" showIcon closable>{e}</Message>)
      })
    }
  }

  const validate = (data) => {
    const errors = {}
    if (!data.email) {
      errors.email = t('Errors.empty_email')
    }
    const emailPattern = new RegExp('^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$', 'i')
    if (data.email && !emailPattern.test(data.email.toLowerCase())) {
      errors.email = t('Errors.valid_email')
    }
    if (!data.password) {
      errors.password = t('Errors.empty_password')
    }
    const passwordPattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])', 'i')
    if (!passwordPattern.test(data.password)) {
      errors.password = t('signup.passwordtooltip')
    }
    return errors
  }

  return (
    <div className='auth-wrapper-parent'>
      <div className='auth-wrapper'>
        <div className='auth-modal'>
          <h3>{t('auth.title')}<LanguageButton /></h3>
          <br />
          <div className='auth-strip'>
            <button onClick={() => setType('login')} style={authType === 'login' ? { backgroundColor: 'white' } : {}}>{t('welcome.signin')}</button>
            <button onClick={() => setType('reg')} style={authType === 'reg' ? { backgroundColor: 'white' } : {}}>{t('welcome.signup')}</button>
          </div>
          <div className='auth-login' hidden={authType !== 'login'}>
            <h5>{t('common.email')}</h5>
            <Input
              placeholder={t('common.email')} type='email' id={datalogin.email} onChange={e => setDatalogin({
                ...datalogin,
                email: e
              })} size='md'
            />
            <h5>{t('common.password')}</h5>
            <InputGroup inside>
              <Input
                placeholder={t('common.password')} size='md'
                id={datalogin.password}
                type={passwordType}
                onChange={e => setDatalogin({
                  ...datalogin,
                  password: e
                })}
              />
              <InputGroup.Addon>
                {eye === 'eye' ? <Eye onClick={togglePassword} style={{ cursor: 'pointer' }}/> : <EyeSlash onClick={togglePassword} style={{ cursor: 'pointer' }}/> }
              </InputGroup.Addon>
            </InputGroup>
            <br />
            <div className='auth-flex'>
              <Button
                appearance='primary' size='xxl' onClick={onSubmitLogin} loading={loading}
              >
                {t('login.button')}
              </Button>
            </div>
          </div>
          <div className='auth-reg' hidden={authType !== 'reg'}>
            <h5>{t('common.name')}</h5>

            <Input
              placeholder={t('common.name')} onChange={e => setDataReg({
                ...datareg,
                first_name: e
              })} size='md'
            />

            <h5>{t('common.surname')}</h5>

            <Input
              placeholder={t('common.surname')} onChange={e => setDataReg({
                ...datareg,
                last_name: e
              })} size='md'
            />

            <h5>Год рождения</h5>

            <Input
              placeholder='Год рождения' onChange={e => setDataReg({
                ...datareg,
                birth_year: e
              })} size='md'
            />

            <h5>Пол</h5>

            <RadioGroup
              inline style={{ display: 'flex', justifyContent: 'space-around' }} onChange={e => setDataReg({
                ...datareg,
                gender: e
              })} size='md'
            >
              <Radio value={1}>Женский</Radio>
              <Radio value={0}>Мужской</Radio>
            </RadioGroup>

            <h5>{t('common.email_dif')}</h5>

            <Input
              placeholder={t('common.email_dif')} csize='md' type='email' onChange={e => setDataReg({
                ...datareg,
                email: e
              })}
            />

            <h5>{t('common.password')}</h5>

            <Whisper
              trigger='focus' speaker={
                <Tooltip>{t('signup.passwordtooltip')} </Tooltip>
                }
            >
              <InputGroup inside>
                <Input
                  placeholder={t('common.password')} size='md'
                  type={passwordType}
                  onChange={e => setDataReg({
                    ...datareg,
                    password: e
                  })}
                />
                <InputGroup.Addon>
                {eye === 'eye' ? <Eye onClick={togglePassword} style={{ cursor: 'pointer' }}/> : <EyeSlash onClick={togglePassword} style={{ cursor: 'pointer' }}/> }
                </InputGroup.Addon>
              </InputGroup>
            </Whisper>

            <h5>{t('common.regkey')} (для сотрудников)</h5>

            <Input
              placeholder={t('common.regkey')} csize='md' onChange={e => setDataReg({
                ...datareg,
                rkey: e
              })}
            />

            <div className='auth-flex'>
              <Button
                appearance='primary' size='sm' onClick={onSubmitReg} loading={loading} className='m-r-15  inc'
              >
                {t('welcome.signup')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Auth
